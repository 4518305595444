import React, { FC, useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import lottie, { AnimationItem } from 'lottie-web'

const LogoOnPath = require('../images/JSON/cobox-white-on.json')
const LogoPulsePath = require('../images/JSON/cobox-white-pulse.json')

const SVGContainer = styled.svg`
  height: 100%;
  width: 100%;
`

type Props = {
  pulseDelay: number
}

const Logo: FC<Props> = ({ pulseDelay }) => {
  const ContainerRef = useRef(null)
  const [animation, setAnimation] = useState<AnimationItem | undefined>(
    undefined
  )
  const [pulseTrigger, setPulseTrigger] = useState(false)

  useEffect(() => {
    const InitialAnimation = lottie.loadAnimation({
      container: ContainerRef.current!,
      animationData: LogoOnPath,
      loop: false,
      autoplay: true,
      renderer: 'svg',
    })
    setAnimation(InitialAnimation)
    setTimeout(() => {
      setPulseTrigger(true)
    }, pulseDelay)
  }, [])

  useEffect(() => {
    if (pulseTrigger) {
      setPulseTrigger(false)
      if (animation) {
        animation.destroy()
      }
      const newAnimation = lottie.loadAnimation({
        container: ContainerRef.current!,
        animationData: LogoPulsePath,
        loop: false,
        autoplay: true,
        renderer: 'svg',
      })
      setAnimation(newAnimation)
      setTimeout(() => {
        setPulseTrigger(true)
      }, pulseDelay)
    }
  }, [pulseTrigger])
  return (
    <SVGContainer viewBox={'0 0 1100 550'}>
      <g ref={ContainerRef} />
    </SVGContainer>
  )
}

export default Logo
